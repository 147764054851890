import React from 'react';

import './footer.scss';

export default function Footer() {
  return (

    <footer className="footer">
      {/* <p className="footer__text">Built in React</p> */}
    </footer>

  )
}